<template>
  <v-card
    @click="abre_modal_view_gestordepedido(kanban)"
    class="card-list-item not-selectable"
    hover
    color="#f2f2f2"
    style="border-radius: 10px !important; cursor: pointer;"
  >
    <div :class="{
      'move-card-item': $vuetify.breakpoint.lgAndUp
    }" class="expande-horizonal wrap">
      <v-flex xs12>
        <v-icon
          size="30"
          :class="{
            'move-card-item': $vuetify.breakpoint.smAndDown
          }"
          color="#333">
          mdi-gesture-tap-hold
        </v-icon>
        <v-chip :style="`border-radius: 3px;`" x-small class="fonte fonteMini">
          #{{ kanban.id_transacao }}
        </v-chip>
        <v-chip
          :style="`border-radius: 3px;`"
          x-small
          class="fonte mr-1 ml-1 fonteMini"
        >
          {{ $helper.formataSaldo(kanban.compra.total_recebido || "0") }} /
          {{ $helper.formataSaldo(kanban.compra.preco_total) }}
        </v-chip>
        <v-chip
          :style="`border-radius: 3px;`"
          x-small
          color="orange lighten-2"
          class="fonte mr-1 ml-1 fonteMini"
          v-if="kanban.compra.desconto"
        >
          - {{ $helper.formataSaldo(kanban.compra.desconto) }}
        </v-chip>
        <v-chip
          v-if="kanban.compra.tags"
          v-for="(tag, i) in kanban.compra.tags"
          :key="`tag-${kanban._id}-${i}`"
          :color="tag.custom_value.color"
          x-small
          dark
          style="border-radius: 3px;"
          class="fonte mr-1 fonteMini"
        >
          <span class="fonte text-lowercase fonteMini">{{
            tag.custom_value.name
          }}</span>
        </v-chip>
      </v-flex>
      <v-flex xs12>
        <div
          v-for="(itemCompra, i) in kanban.compra.produtos"
          class="expande-horizontal wrap"
          :key="`item-${i}-${itemCompra._id}`"
        >
          <v-chip
            v-for="(item, ind) in resumeQtdOfItems(itemCompra.ficha_tecnica)"
            :key="`item-compra-${ind}`"
            v-show="item.type === 'Peça Principal'"
            x-small
            style="margin-right: 2px; margin-top: 2px; border-radius: 3px;"
            class="fonteMini fonte"
          >
            {{ item.qtd }}x {{ item.descricao }}
          </v-chip>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-list color="transparent" nav dense class="px-1">
          <v-divider></v-divider>
          <v-list-item style="height: 30px;" class="px-0 pr-2">
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonte-bold" style="font-size: 13pt;">
                  {{ kanban.compra.cliente.nome }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="fonteMini">
                  {{ kanban.compra.cliente.telefone }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action @click="openWhats(kanban.compra.cliente)">
              <v-avatar size="27" color="green darken-1">
                <v-icon size="15" color="#f2f2f2">mdi-whatsapp</v-icon>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-flex>
      <v-flex xs12>
        <div
          class="pl-3 pr-0 expande-horizontal"
          style="justify-content: space-between; align-items: center;"
        >
          <v-icon v-if="kanban.imagens.length" color="#333" size="15">
            mdi-image
          </v-icon>
          <v-icon v-if="kanban.arquivos.length" color="#333" size="15">
            mdi-attachment
          </v-icon>
          <!-- <div v-if="kanban.compra.data_de_entrega_original"> -->
          <div
            style="display: flex; flex-direction: row; flex-wrap: nowrap;"
            :class="{ 'px-2': kanban.arquivos.length || kanban.imagens.length }"
          >
            <span style="white-space: nowrap; color: #333;" class="fonteMini">
              {{ $moment(kanban.created_at).format("DD/MM") }}
            </span>
            <!-- <v-icon class="" :color="$theme.third" size="12">
              mdi-arrow-right
            </v-icon>
            <span
              style="white-space: nowrap; color: #333;"
              class="fonteMini"
              v-if="kanban.external_movimentation"
            >
              {{
                $moment(
                  kanban.external_movimentation.visit_date,
                  "DD/MM/YYYY"
                ).format("DD/MM")
              }}
            </span>
            <span
              style="white-space: nowrap; color: #333;"
              class="fonteMini"
              v-else
            >
              definir
            </span> -->
          </div>
          <MembersPlugin
            origin="cardsale"
            :dataOrigin="{ ...kanban, state: 'progress-view' }"
          />
        </div>
      </v-flex>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import MembersPlugin from "@/apps/CosmosPluginStore/plugins/members/index.vue";

export default {
  props: {
    kanban: {
      type: Object,
      required: true
    }
  },
  components: {
    MembersPlugin
  },
  data() {
    return {
      initialized: false
    };
  },
  computed: {
    resumeQtdOfItems() {
      return ficha_tecnica => {
        let resume = [];
        ficha_tecnica.forEach(item => {
          let index = resume.findIndex(
            resumeItem => resumeItem.descricao === item.descricao
          );
          if (index === -1) {
            resume.push({ ...item, qtd: 1 });
          } else {
            resume[index].qtd++;
          }
        });
        return resume;
      };
    },
    images() {
      let images = [];
      this.kanban.compra.produtos.forEach(image => {
        if (image.imagens && image.imagens.length > 0) {
          image.imagens.map(img => {
            images.push(img);
          });
        }
        images.push(image);
      });
      return images;
    }
  },
  methods: {
    ...mapActions(["abre_modal_view_gestordepedido", "listar_transacoes"]),
    openWhats(client) {
      window.open(
        `https://api.whatsapp.com/send?phone=55${client.telefone}&text=Olá, ${client.nome}!`
      );
    },
    update_status(compra, status) {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...compra,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  mounted() {
    setTimeout(() => {
      this.initialized = true;
    }, 2500);
  }
};
</script>

<style>
.card-list-item {
  margin-bottom: 10px;
  border-radius: 10px !important;
  padding-left: 5px;
  padding-top: 2px;
  background: #e5e5e5;
}
.move-card-item {
  border-radius: 10px !important;
}
.cool-shaddow {
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
}
</style>
