<template>
  <v-list-item @click="abre_modal_view_gestordepedido(kanban)"
  class="not-selectable item-mobile-sale fonte px-2">
    <v-list-item-content>
      <v-list-item-title>
        <span class="fonte" style="font-size: 12pt;">
          {{ kanban.compra.cliente.nome }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <span class="fonteMini">
          {{ kanban.compra.cliente.telefone }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-chip :style="`border-radius: 3px;`" x-small class="fonte fonteMini">
          #{{ kanban.id_transacao }}
        </v-chip>
        <v-chip
          :style="`border-radius: 3px;`"
          x-small
          class="fonte mr-1 ml-1 fonteMini"
        >
          {{ $helper.formataSaldo(kanban.compra.total_recebido || "0") }} /
          {{ $helper.formataSaldo(kanban.compra.preco_total) }}
        </v-chip>
        <v-chip
          :style="`border-radius: 3px;`"
          x-small
          color="orange lighten-2"
          class="fonte mr-1 ml-1 fonteMini"
          v-if="kanban.compra.desconto"
        >
          - {{ $helper.formataSaldo(kanban.compra.desconto) }}
        </v-chip>
        <v-chip
          v-if="kanban.compra.tags"
          v-for="(tag, i) in kanban.compra.tags"
          :key="`tag-${kanban._id}-${i}`"
          :color="tag.custom_value.color"
          x-small
          dark
          style="border-radius: 3px;"
          class="fonte mr-1 fonteMini"
        >
          <span class="fonte text-lowercase fonteMini">{{
            tag.custom_value.name
          }}</span>
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action @click="openWhats(kanban.compra.cliente)">
      <v-avatar size="27" color="green darken-1">
        <v-icon size="15" color="#f2f2f2">mdi-whatsapp</v-icon>
      </v-avatar>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from "vuex";
import MembersPlugin from "@/apps/CosmosPluginStore/plugins/members/index.vue";

export default {
  props: {
    kanban: {
      type: Object,
      required: true
    }
  },
  components: {
    MembersPlugin
  },
  data() {
    return {
      initialized: false
    };
  },
  computed: {
    resumeQtdOfItems() {
      return ficha_tecnica => {
        let resume = [];
        ficha_tecnica.forEach(item => {
          let index = resume.findIndex(
            resumeItem => resumeItem.descricao === item.descricao
          );
          if (index === -1) {
            resume.push({ ...item, qtd: 1 });
          } else {
            resume[index].qtd++;
          }
        });
        return resume;
      };
    },
  },
  methods: {
    ...mapActions(["abre_modal_view_gestordepedido", "listar_transacoes"]),
    openWhats(client) {
      window.open(
        `https://api.whatsapp.com/send?phone=55${client.telefone}&text=Olá, ${client.nome}!`
      );
    },
    update_status(compra, status) {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...compra,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    }
  },
};
</script>

<style>
.item-mobile-sale {
  background: #f2f2f2;
  border-bottom: 1px solid #dedddd;
}
</style>
